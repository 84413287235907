import { Container } from "@material-ui/core"
import * as api from "api"
import { trackImpressions } from "common/analytics"
import CarModel from "features/models/CarModel"
import { GetStaticProps } from "next"
import { NextSeo } from "next-seo"
import { useEffect } from "react"
import { wrapper } from "store"
import styled from "styled-components"

const title = "קנו רכב Online [בחרו רמת גימור, צבע רכב ועוד]"
const description =
  "קנו את המאזדה הבאה שלכם - תוכלו לבחור את רמת הגימור, את צבע הרכב, ריפוד ואביזרים. היכנסו לאתר החדש!"

const StyledHeadline = styled.h1`
  font-weight: bold;
  margin-top: ${(p) => p.theme.spacing(4)}px;
  margin-bottom: 0;
  font-size: 1.6875rem;
  text-align: center;
  letter-spacing: 1.08px;

  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 2.125rem;
    margin-top: ${(p) => p.theme.spacing(6)}px;
    letter-spacing: 1.36px;
  }
`
const StyledSubtitle = styled.p`
  margin-top: 11px;
  font-size: 1rem;
  margin-bottom: 0;
  text-align: center;
  color: ${(p) => p.theme.palette.text.secondary};
  letter-spacing: 0.64px;

  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 1.125rem;
    margin-top: ${(p) => p.theme.spacing(1)}px;
    letter-spacing: 0.72px;
  }
`
const StyledModels = styled.ul`
  list-style: none;
  padding: 10px 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(p) => p.theme.colors.lightGrey};

  ${(p) => p.theme.breakpoints.up("md")} {
    flex-direction: row;
    flex-wrap: wrap;
    border-top: none;
  }
`

export const getStaticProps = wrapper.getStaticProps<
  ReturnType<GetStaticProps<HomeProps>>
>(async () => {
  const {
    data: { body },
  } = await api.models.getModels()

  return { props: { data: body } }
})

type HomeProps = { data: api.models.ModelsResponse }

const Home = ({ data }: HomeProps) => {
  useEffect(() => {
    const modelsImpressions = data.map((model) => ({
      name: model.details.name,
      id: model.details.id,
    }))
    trackImpressions(modelsImpressions)
  }, [data])

  const firstImageUrl = data[0].details.image.imageUrl

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          title,
          description,
          images: [
            { url: firstImageUrl + "?width=1200", width: 1200, height: 675 },
            { url: firstImageUrl + "?width=256", width: 256, height: 144 },
          ],
        }}
      />

      <Container>
        <StyledHeadline>
          בוחרים מהמלאי דגם, רמת גימור וצבע ונהנים מהזמנת Online קלה ומהירה!
        </StyledHeadline>

        <StyledSubtitle>הרכיבו את המאזדה החדשה שלכם! שנצא לדרך?</StyledSubtitle>

        <StyledModels>
          {data.map((model) => (
            <CarModel key={model.details.id} model={model} />
          ))}
        </StyledModels>
      </Container>
    </>
  )
}

export default Home
