/**
 * Format a number as a price, i.e: add thousand separators and prepend the ILS
 * currency symbol.
 *
 * @param price The price
 *
 * @returns A formatted price string
 */
export function formatPrice(price: number) {
  // TODO: Remove this
  if (!price) return "₪0"
  return "₪" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
