import * as api from "api"
import { trackProductClick } from "common/analytics"
import { Desktop, Mobile } from "common/MediaQueries"
import { formatPrice } from "common/price"
import NextLink from "next/link"
import React from "react"
import routes from "routes"
import styled from "styled-components"

const StyledModel = styled.li`
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: ${(p) => p.theme.spacing(1, 0)};
    margin-left: ${(p) => p.theme.spacing(-2)}px;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    width: calc(100% / 3);
    position: relative;
    display: flex;
    justify-content: center;
    margin: ${(p) => p.theme.spacing(4, 0)};

    :after {
      content: "";
      width: 1px;
      height: 150px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${(p) => p.theme.colors.lightGrey};
    }

    :nth-child(3n):after {
      content: none;
    }
    :last-child:after {
      content: none;
    }
  }
`
const StyledLink = styled.a`
  display: block;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${(p) => p.theme.palette.text.primary};

  ${(p) => p.theme.breakpoints.down("sm")} {
    min-height: 88px;
    flex-direction: row-reverse;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    flex-direction: column;
    width: 100%;
  }
`
const StyledImage = styled.img`
  flex-shrink: 0;

  ${(p) => p.theme.breakpoints.down("sm")} {
    max-height: 100%;
    width: 165px;
    object-fit: contain;
    object-position: right;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    width: 100%;
  }
`
const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;

  ${(p) => p.theme.breakpoints.up("md")} {
    margin-right: 0;
    align-items: center;
    margin-top: auto;
  }
`
const StyledAnnouncement = styled.span`
  font-size: 0.875rem;
  color: ${(p) => p.theme.colors.red};
  font-weight: bold;
  letter-spacing: 0.56px;
  margin-bottom: 2px;

  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 1.125rem;
    letter-spacing: 0.72px;
  }
`
const StyledModelName = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  margin: 0;
  text-align: right;
  letter-spacing: 0.8px;
  margin-bottom: 2px;

  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 1.5rem;
    text-align: center;
    letter-spacing: 2.4px;
  }
`
const StyledModelPrice = styled.p`
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  letter-spacing: 0.64px;
  margin-top: 0px;

  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 1.375rem;
    letter-spacing: 0.88px;
    margin-top: 0;
  }
`

const StyledFeePrice = styled.p`
  font-weight: normal;
  font-size: 0.75rem;
  margin: 0;
  color: ${(p) => p.theme.palette.text.secondary};
  letter-spacing: 0.64px;

  ${(p) => p.theme.breakpoints.down("sm")} {
    text-align: right;
    margin-top: 5px;
    padding-left: 10px;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 0.875rem;
    letter-spacing: 0.56px;
    margin-top: 5px;
  }
`

const CarModel = ({ model }: { model: api.models.ModelResponse }) => (
  <StyledModel key={model.details.id}>
    <NextLink
      href="/[model]"
      as={`/${model.details.id}#${routes.array[0].path}`}
      passHref
    >
      <StyledLink
        onClick={() => trackProductClick(model.details.name, model.details.id)}
      >
        <Mobile aboveTheFold>
          <StyledImage
            src={`${model.details.image.imageUrl}?width=165`}
            srcSet={`${model.details.image.imageUrl}?width=165 1x,
                    ${model.details.image.imageUrl}?width=330 2x,
                    ${model.details.image.imageUrl}?width=495 3x`}
            sizes="165px"
            alt=""
          />
        </Mobile>

        <Desktop>
          <StyledImage
            src={`${model.details.image.imageUrl}?width=410`}
            alt=""
          />
        </Desktop>

        <StyledDetails>
          {model.details.announcement && (
            <StyledAnnouncement>
              {model.details.announcement}
            </StyledAnnouncement>
          )}

          <StyledModelName>{model.details.name}</StyledModelName>

          <StyledModelPrice>
            החל מ-{formatPrice(model.price.totalPrice)}
          </StyledModelPrice>
          <StyledFeePrice>
            בתוספת אגרת רישוי בסך{" "}
            {formatPrice(
              model.details.lowestFinishingLevelLicensingFee.totalPrice
            )}{" "}
            כולל מע&quot;מ
          </StyledFeePrice>
        </StyledDetails>
      </StyledLink>
    </NextLink>
  </StyledModel>
)

export default CarModel
